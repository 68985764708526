import {KeyValue} from '@angular/common'
import {Pipe, PipeTransform} from '@angular/core'
import {TranslatePipe} from './translate.pipe'

@Pipe({
  standalone: true,
  name: 'translateFilter'
})
export class TranslateFilterPipe implements PipeTransform {

  private translatePipe = new TranslatePipe()

  transform(value: string[], filter: string): KeyValue<string, string>[] {
    const regExp = new RegExp(filter, 'i')
    return value
      .map(v => {
        return {
          key: v,
          value: this.translatePipe.transform(v)
        }
      })
      .filter(v => RegExp(regExp).exec(v.value))
  }
}