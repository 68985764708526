import {DecimalPipe} from '@angular/common'
import {Component, input, OnChanges, OnInit, output, signal} from '@angular/core'
import {FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators} from '@angular/forms'
import {MatFormField, MatLabel, MatSuffix} from '@angular/material/form-field'
import {MatIcon} from '@angular/material/icon'
import {MatInput} from '@angular/material/input'
import {MatOption, MatSelect} from '@angular/material/select'
import {MatTooltip} from '@angular/material/tooltip'
import {Sexes, TSexes} from '@ellen/user-be'
import {filter} from 'rxjs'
import {
  PROFILE_MAX_AGE,
  PROFILE_MAX_HEIGHT,
  PROFILE_MAX_WEIGHT,
  PROFILE_MIN_AGE,
  PROFILE_MIN_HEIGHT,
  PROFILE_MIN_WEIGHT
} from '../../../../application/constants'
import {TranslatePipe} from '../../../../pipes/translate.pipe'
import {AvatarComponent} from '../../../common/avatar/avatar.component'
import {User} from '../../../../utils/user.class'

@Component({
  selector: 'eln-family-profile',
  standalone: true,
  imports: [
    AvatarComponent,
    ReactiveFormsModule,
    MatFormField,
    MatSuffix,
    MatInput,
    MatSelect,
    MatOption,
    MatLabel,
    TranslatePipe,
    DecimalPipe,
    MatIcon,
    MatTooltip,
    FormsModule
  ],
  templateUrl: './family-profile.component.html',
  styleUrl: './family-profile.component.scss'
})
export class FamilyProfileComponent implements OnInit, OnChanges {

  public user = input.required<User>()

  public updatedUser = output<User>()

  public isUserValid$ = signal<boolean>(false)

  protected readonly sexes = Sexes

  public form = new FormGroup({
    name: new FormControl<string>('', {
      validators: [Validators.required, Validators.minLength(3)]
    }),
    email: new FormControl<string>('', {
      validators: [Validators.email, Validators.minLength(3)]
    }),
    weight: new FormControl<number | null>(null, {
      validators: [
        Validators.required,
        Validators.min(PROFILE_MIN_WEIGHT),
        Validators.max(PROFILE_MAX_WEIGHT)
      ]
    }),
    height: new FormControl<number | null>(null, {
      validators: [
        Validators.required,
        Validators.min(PROFILE_MIN_HEIGHT),
        Validators.max(PROFILE_MAX_HEIGHT)
      ]
    }),
    age: new FormControl<number | null>(null, {
      validators: [
        Validators.required,
        Validators.min(PROFILE_MIN_AGE),
        Validators.max(PROFILE_MAX_AGE)
      ]
    }),
    sex: new FormControl<TSexes | null>(null, {
      validators: [Validators.required],
      nonNullable: true
    })
  })

  public ngOnInit(): void {
    // We listen to every change in form's data.
    // With every change, form's validity will be emitted using an output.
    this.form.valueChanges.subscribe(() =>
      this.isUserValid$.set(this.form.valid))

    // Also, with every change, but only every 200ms (debounceTime), we'll
    // send the updated user
    this.form.valueChanges.pipe(
    ).subscribe({
      next: (v) => {
        this.setFrom(v)
        this.updatedUser.emit(this.user())
      }
    })

    // Set automatic limitations in some fields
    this.setAutocorrectedLimits(this.form.controls.age,
      PROFILE_MIN_AGE, PROFILE_MAX_AGE)
    this.setAutocorrectedLimits(this.form.controls.weight,
      PROFILE_MIN_WEIGHT, PROFILE_MAX_WEIGHT)
    this.setAutocorrectedLimits(this.form.controls.height,
      PROFILE_MIN_HEIGHT, PROFILE_MAX_HEIGHT)
  }

  public ngOnChanges(): void {
    // When a parameter changes, in this case just "user()", then we clear the
    // form and patch new user's values.
    this.form.reset(undefined, {emitEvent: false})
    this.form.controls.sex.setValue(this.user().sex, {emitEvent: false})
    this.form.controls.age.setValue(this.user().age, {emitEvent: false})
    this.form.controls.height.setValue(this.user().height, {emitEvent: false})
    this.form.controls.weight.setValue(this.user().weight, {emitEvent: false})
    this.form.controls.email.setValue(this.user().email, {emitEvent: false})
    this.form.controls.name.setValue(this.user().name, {emitEvent: false})

    if (!this.user().editable) {
      Object.values(this.form.controls).forEach(control => {
        control.disable({emitEvent: false})
      })
    }
    this.isUserValid$.set(this.form.valid)
  }

  public avatarImageChange(imageResponse: {
    id: string,
    url: string | undefined
  }) {
    this.user().avatar = imageResponse.id
    this.user().avatarUrl = imageResponse.url
    this.updatedUser.emit(this.user())
  }

  public activitySelect(activity: number) {
    this.user().activityLevel = activity
    this.updatedUser.emit(this.user())
  }

  private setAutocorrectedLimits(control: FormControl<number | null>, min: number, max: number) {
    control.valueChanges
      // We cannot user filter(Boolean) because 0 (zero) would be excluded
      .pipe(filter((val): val is number => val !== null))
      .subscribe((val) => {
        val = Math.max(min, val)
        val = Math.min(max, val)
        control.setValue(val, {emitEvent: false})
      })
  }

  private setFrom(v: any) {
    if ('age' in v) {
      this.user().age = v.age
    }
    if ('sex' in v) {
      this.user().sex = v.sex
    }
    if ('weight' in v) {
      this.user().weight = v.weight
    }
    if ('height' in v) {
      this.user().height = v.height
    }
    if ('name' in v) {
      this.user().name = v.name
    }
    if ('email' in v) {
      this.user().email = v.email
    }
  }
}
