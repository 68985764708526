import {Component, inject, model} from '@angular/core'
import {FamilyProfileComponent} from '../profile/family-profile.component'
import {MatButton} from '@angular/material/button'
import {MatDialogClose} from '@angular/material/dialog'
import {MatFormField, MatLabel} from '@angular/material/form-field'
import {FormsModule} from '@angular/forms'
import {MatInput} from '@angular/material/input'
import {UserService} from '../../../../services/user.service'

@Component({
  selector: 'eln-invite-user-dialog',
  standalone: true,
  imports: [
    FamilyProfileComponent,
    MatButton,
    MatLabel,
    MatDialogClose,
    MatFormField,
    FormsModule,
    MatInput
  ],
  templateUrl: './invite-user-dialog.component.html',
  styleUrl: './invite-user-dialog.component.scss'
})
export class InviteUserDialogComponent {
  //public dialogRef = inject(MatDialogRef<AddUserDialogComponent>)

  public email = model<string | null>(null)

  public sent: boolean = false

  private service = inject(UserService)

  public save(): void {
    this.service.invite(this.email()!).subscribe({
      next: () => {
        this.sent = true
      }
    })
  }
}
