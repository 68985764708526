@if (user.id === CREATING_USER_ID) {
  <div class="family-settings-loading-holder">
    <mat-spinner></mat-spinner>
  </div>
} @else {
  <mat-tab-group mat-stretch-tabs>
    <mat-tab label="Preferenser">
      <eln-family-preferences
        [user]="user"
        [resetStatus$]="resetEditingOptions$"
        (updatedUser)="updateUser($event)"
      ></eln-family-preferences>
    </mat-tab>

    <mat-tab label="Profil">
      <eln-family-profile
        [user]="user"
        (updatedUser)="updateUser($event)"
      ></eln-family-profile>
    </mat-tab>
  </mat-tab-group>

  <mat-divider></mat-divider>

  <div class="family-setting-actions-holder">
    <!-- Save button shows a different layout, with tooltip, if profile is not valid -->
    @if (!user.isValid) {
      <button class="eln-btn-small setting-action-save disabled" color="primary"
              mat-raised-button
              #tooltip="matTooltip"
              matTooltip="Du behöver fylla i din profil först"
              [matTooltipHideDelay]="500"
              (click)="tooltip.show()">
        Spara
      </button>
    } @else if (user.editable) {
      <button class="eln-btn-small" color="primary"
              #saveButton
              mat-raised-button
              [disabled]="!isUserModified || userService.isUpdatingUser$() === user.id"
              (click)="save()">
        @if (userService.isUpdatingUser$() === user.id) {
          <mat-spinner></mat-spinner>
        } @else {
          {{ saveButton.disabled ? 'Sparad' : 'Spara' }}
        }
      </button>
    }

    @if (configService.loggedInUser$()?.sub !== user.id) {
      <button class="eln-btn-small"
              mat-raised-button
              (click)="removeFromFamily()">
        Ta bort från familj
      </button>
    } @else {
      <button class="eln-btn-small family-setting-action-close-account"
              mat-raised-button
              (click)="onCloseAccountClick()">
        Avsluta konto
      </button>
    }
  </div>
}