import {Component} from '@angular/core'
import {MatCard} from '@angular/material/card'

@Component({
  selector: 'eln-gpt-loader',
  templateUrl: './gpt-loader.component.html',
  styleUrls: ['./gpt-loader.component.scss'],
  imports: [
    MatCard
  ],
  standalone: true
})
export class GptLoaderComponent {
}
