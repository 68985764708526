<div class="invite-user-holder">
  <div class="title">
    Bjud in användare
  </div>
  @if (!sent) {
    <p>Ange e-postadress till den du vill bjuda in.</p>
    <mat-form-field>
      <mat-label>E-post</mat-label>
      <input
        placeholder="namn@familj.com"
        matInput
        name="email"
        #emailValue=ngModel
        [(ngModel)]="email" [minLength]="3"
        email required
      >
    </mat-form-field>

    <div class="invite-user-actions-holder">
      <button class="eln-btn" mat-raised-button color="primary"
              (click)="save()"
              [disabled]="emailValue.invalid">Bjud in
      </button>
      <button class="eln-btn" mat-raised-button mat-dialog-close>
        Avbryt
      </button>
    </div>
  } @else {
    <p>Mottagaren har fått en inbjudan. Om inbjudan accepteras kommer hen att komma med i din familj. </p>
    <div class="invite-user-actions-holder">
      <button class="eln-btn"
              mat-dialog-close
              mat-raised-button color="primary"
      >Stäng
      </button>
    </div>
  }

</div>