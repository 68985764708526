import {Pipe, PipeTransform} from '@angular/core'

@Pipe({
  standalone: true,
  name: 'split'
})
export class SplitPipe implements PipeTransform {
  transform(value: string | undefined = 'Okänd Användare'): string[] {
    return value.split(' ')
  }
}
