import {Component, inject} from '@angular/core'
import {MatButton} from '@angular/material/button'
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogClose,
  MatDialogRef
} from '@angular/material/dialog'
import {DEFAULT_DIALOG_STYLE} from '../../../application/constants'

interface IConfirmationDialogData {
  title: string
  okButton: string
  cancelButton?: string
}

@Component({
  selector: 'eln-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss'],
  imports: [
    MatButton,
    MatDialogClose
  ],
  standalone: true
})
export class ConfirmationDialogComponent {

  public data: IConfirmationDialogData = inject(MAT_DIALOG_DATA)

  public static open(dialog: MatDialog, data: IConfirmationDialogData): MatDialogRef<ConfirmationDialogComponent> {
    return dialog.open(ConfirmationDialogComponent, {
      ...DEFAULT_DIALOG_STYLE,
      disableClose: true,
      data
    })
  }
}
