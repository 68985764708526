<div class="confirmation-dialog-holder">
  <h4 class="confirmation-dialog-title">
    {{ data.title }}
  </h4>

  <div class="confirmation-dialog-actions-holder">
    <button class="eln-btn"
            mat-raised-button
            color="secondary"
            [matDialogClose]="false">
      {{ data.cancelButton ?? 'Avbryt' }}
    </button>

    <button class="eln-btn"
            mat-raised-button
            color="primary"
            [matDialogClose]="true">
      {{ data.okButton }}
    </button>
  </div>
</div>