import {Component, input, output} from '@angular/core'
import {MatIcon} from '@angular/material/icon'

@Component({
  selector: 'eln-family-preferences-chip',
  standalone: true,
  imports: [MatIcon],
  templateUrl: './family-preferences-chip.component.html',
  styleUrl: './family-preferences-chip.component.scss'
})
export class FamilyPreferencesChipComponent {

  public value = input.required<string>()

  public editable = input<boolean>(true)

  public remove = output()
}
