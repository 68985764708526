<button class="family-preference-chip">
  {{ value() }}

  @if (editable()) {
    <div class="family-preference-chip-close-icon"
         tabindex="-1"
         (keydown.enter)="remove.emit()"
         (click)="remove.emit()"
    >
      <mat-icon>close</mat-icon>
    </div>
  }
</button>